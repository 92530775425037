body {
   background-color: white;
}

* {
   font-size: 100%;
}

*, *:before, *:after {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   margin: 0; 
   padding: 0; 
 }
 
 ul {
   margin: 0;
   padding: 0;
   list-style: none;
 }

 a {
   text-decoration: none;
 }